import React, { forwardRef, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { backgroundImage } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Button from '../button';
import Popover from '../popover';
import Translate from '../translate';
import { userState, workspaceIdState } from '../../state';

import useFileDragAndDrop from '../../hooks/useFileDragAndDrop';

import addToast from '../../utils/addToast';
import translate from '../../utils/translate';

import { getFileNameFromSource, guid, putWorkspaceConfigFormatter, successStatus } from '../../helpers';

import uploadService from '../../services/uploadService';
import connectionService from '../../services/connectionService';

import { PROFILE, URLS } from '../../constants/apiKeys';

import './index.scss';

const Avatar = forwardRef(function Avatar(props, ref) {
    const {
        url,
        badge,
        onClick,
        editable,
        className,
        size = 'big',
        onDoubleClick,
        fallbackIcon = 'icon-a-user',
    } = props;

    const [user, setUser] = useRecoilState(userState);
    const workspaceId = useRecoilValue(workspaceIdState);

    const [loading, setLoading] = useState(false);

    const isUserEdit = editable === 'user';

    const updateChanges = (key, meta) => {
        setLoading(true);
        const modifiedUser = {
            ...user,
            ...(isUserEdit ? ({
                avatar: key,
                color: meta?.color || '',
                blurHash: meta?.blurHash || '',
            }) : ({
                workspace: {
                    ...putWorkspaceConfigFormatter(user.workspace),
                    avatar: key,
                    color: meta?.color || '',
                    blurHash: meta?.blurHash || '',
                }
            }))
        };
        const params = isUserEdit ? modifiedUser : modifiedUser.workspace;
        const putUrl = isUserEdit ? URLS[PROFILE] : `/workspaces/${workspaceId}/configs`;

        connectionService.putJson(putUrl, params).subscribe(res => {
            setLoading(false);
            if (successStatus(res)) {
                connectionService.getJson(PROFILE, {}, false).subscribe(res => {
                    setUser(res);
                    addToast({
                        title: <Translate>Successfully saved</Translate>,
                        message: isUserEdit ? (
                            translate('Your avatar is successfully updated.')
                        ) : translate('_NAME_ avatar is successfully updated.', { _NAME_: user?.workspace?.name }),
                    });
                });
            }
        });
    };

    const handleUpload = val => {
        setLoading(true);
        const file = val.target ? val.target.files[0] : val;
        if (file) {
            const id = guid();
            uploadService.handleUpload(file, id, {
                guid: id,
                getterUrl: `${isUserEdit ? `/users/me` : `/workspaces/${workspaceId}`}/avatar-upload-url`,
            }).then(val => {
                const url = val?.output?.results?.[0]?.request?.responseURL?.replace(/\?.*$/, '');
                if (url) {
                    updateChanges(getFileNameFromSource(url), {
                        color: val.data.color,
                        blurHash: val.data.blurHash,
                    });
                } else {
                    addToast({
                        type: 'error',
                        message: <Translate>Something went wrong, please try again</Translate>,
                    });
                }
            }).catch(() => addToast({
                type: 'error',
                message: <Translate>Something went wrong, please try again</Translate>,
            })).finally(() => setLoading(false));
        }
    };

    const uploadRef = useRef(null);
    const { dragged } = useFileDragAndDrop(uploadRef, handleUpload);

    const image = url;

    const actionButtonSharedProps = {
        type: 'div',
        size: 'small',
        color: 'accent',
    };

    return (
        <div
            ref={ref}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            className={classNames(
                `avatar-holder`,
                `s-${size}`,
                `button-external-hover`,
                className,
                {
                    'pointer-events-none': (!onClick && !editable) || loading,
                }
            )}
        >
            <div
                className={classNames(
                    `avatar-badge`,
                    {
                        'active': badge,
                    }
                )}
            />
            <figure style={backgroundImage(image)}>
                {!image && (
                    <div className='avatar-placeholder-icon absolutely-splash'>
                        <Icon
                            type={fallbackIcon}
                            size={(size === 'extra-big' || size === 'extra-large') ? 42 : size === 'small' ? 18 : 24}
                        />
                    </div>
                )}
            </figure>
            {!!editable && (
                <>
                    <label
                        ref={uploadRef}
                        className='absolutely-splash'
                    >
                        <input
                            type='file'
                            accept='image/*'
                            onChange={handleUpload}
                        />
                        <div
                            className={classNames(
                                `a-edit-button-holder`,
                                {
                                    'active': !loading && !dragged,
                                }
                            )}
                        >
                            {image ? (
                                <Popover
                                    width={280}
                                    position='right'
                                    menu={[
                                        {
                                            name: <Translate>Edit</Translate>,
                                            icon: {
                                                type: 'icon-a-edit',
                                            },
                                            onClick: () => uploadRef?.current?.click(),
                                        },
                                        {
                                            divider: true,
                                        },
                                        {
                                            icon: {
                                                type: 'icon-a-trash',
                                            },
                                            danger: true,
                                            name: <Translate>Delete</Translate>,
                                            onClick: () => updateChanges(''),
                                        }
                                    ]}
                                >
                                    <Button
                                        {...actionButtonSharedProps}
                                        icon={{
                                            size: 18,
                                            type: 'icon-a-more-horiz',
                                        }}
                                    />
                                </Popover>
                            ) : (
                                <Button
                                    {...actionButtonSharedProps}
                                    icon={{
                                        size: 14,
                                        type: 'icon-a-edit',
                                    }}
                                />
                            )}
                        </div>
                    </label>
                    <ul className='avatar-uploading-states absolutely-splash pointer-events-none'>
                        <li
                            className={classNames(
                                'absolutely-splash',
                                'avatar-spinner',
                                {
                                    'active': loading && !dragged,
                                }
                            )}
                        />
                        <li
                            className={classNames(
                                `absolutely-splash`,
                                `avatar-drop-here`,
                                {
                                    'active': !loading && dragged,
                                }
                            )}
                        >
                            <Icon
                                size={32}
                                type='icon-a-cloud-upload'
                            />
                        </li>
                    </ul>
                </>
            )}
        </div>
    );
});

Avatar.propTypes = {
    url: PropTypes.string,
    size: PropTypes.oneOf([
        'small',
        'medium',
        'big',
        'extra-big',
        'extra-large',
    ]),
    badge: PropTypes.bool,
    onClick: PropTypes.func,
    editable: PropTypes.oneOf([
        'user',
        'workspace'
    ]),
    className: PropTypes.string,
    fallbackIcon: PropTypes.string,
    onDoubleClick: PropTypes.func,
};

export default Avatar;
