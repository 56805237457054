import React, { memo, useCallback, useEffect, useRef } from 'react';
import { conditionalSpread, isFunction, rem } from 'clyne-core';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useInView } from 'react-intersection-observer';
import clasNames from 'classnames';
import PropTypes from 'prop-types';

import Loader from '../loader';

import './index.scss';

const VirtualList = memo(function VirtualList(props) {
    const {
        gap,
        data,
        total,
        padding,
        renderer,
        absolute,
        fetchMore,
        horizontal,
        scrollElement,
        scrollToOnById,
        defaultSize = 30,
        setScrollToOnById,
    } = props;

    const listRef = useRef(null);
    const parentRef = useRef(scrollElement);

    const { ref, inView } = useInView();

    const canFetchMore = data.length < total;

    const estimateSize =  useCallback(() => defaultSize, [defaultSize]);

    const virtualizer = useVirtualizer({
        horizontal,
        estimateSize,
        overscan: 5,
        count: canFetchMore ? data.length + 1 : data.length,
        getScrollElement: () => parentRef.current,
        ...conditionalSpread({
            paddingEnd: gap ? padding - (gap / 2) : padding,
            paddingStart: gap ? padding - (gap / 2) : padding,
        }, !!padding),
    });

    useEffect(() => {
        !!inView && fetchMore();
    }, [inView]); // eslint-disable-line

    const paddingTopBottom = gap ? rem(gap / 2) : 0;
    const paddingLeftRight = padding ? rem(padding) : 0;

    useEffect(() => {
        if (data?.length && scrollToOnById) {
            const index = data.map(item => item?.id).indexOf(scrollToOnById);
            if (!!index || index === 0) {
                virtualizer.scrollToIndex(index, {
                    align: 'center',
                    behavior: 'smooth',
                });
            }
            isFunction(setScrollToOnById, false) && setScrollToOnById(null);
        }
    }, [scrollToOnById, data]); // eslint-disable-line

    const content = (
        <ul
            ref={listRef}
            className={clasNames(
                'virtual-list-holder',
                horizontal ? 'horizontal' : 'vertical',
                {
                    'pointer-events-none': virtualizer.isScrolling,
                }
            )}
            style={{
                [horizontal ? 'width' : 'height']: virtualizer.getTotalSize(),
            }}
        >
            {virtualizer.getVirtualItems().map(virtual => (
                <li
                    key={virtual.key}
                    ref={virtualizer.measureElement}
                    data-index={virtual.index}
                    style={{
                        transform: `translate${horizontal ? 'X' : 'Y'}(${virtual.start}px)`,
                    }}
                >
                    <div
                        className='virtual-list-item'
                        {...conditionalSpread({
                            style: {
                                padding: horizontal ? `${paddingLeftRight} ${paddingTopBottom}` : `${paddingTopBottom} ${paddingLeftRight}`,
                            },
                        }, !!(padding || gap))}
                    >
                        {virtual.index > data.length - 1 ? (
                            <div
                                ref={ref}
                                className='virtual-list-loader'
                            >
                                <Loader />
                            </div>
                        ) : renderer({
                            virtual,
                            data: data[virtual.index],
                        })}
                    </div>
                </li>
            ))}
        </ul>
    );

    return scrollElement ? content : (
        <div
            ref={parentRef}
            className={clasNames(
                'virtual-list-scroller',
                {
                    absolute,
                    horizontal,
                }
            )}
        >
            {content}
        </div>
    );
});

VirtualList.propTypes = {
    gap: PropTypes.number,
    data: PropTypes.array,
    total: PropTypes.number,
    padding: PropTypes.number,
    renderer: PropTypes.func,
    absolute: PropTypes.bool,
    fetchMore: PropTypes.func,
    horizontal: PropTypes.bool,
    defaultSize: PropTypes.number,
    scrollElement: PropTypes.any,
    scrollToOnById: PropTypes.any,
    setScrollToOnById: PropTypes.func,
};

export default VirtualList;
