import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import translate from '../../utils/translate';

import { setCookie } from '../../helpers';

import { authenticationRoutes } from '../../containers/authentication/data';

const AppUrlListener = () => {
    const navigate = useNavigate();

    const getRedirectURL = keyMessage => {
        switch (keyMessage) {
            case 'account_not_found':
            case 'account_exists':
            case 'email_verification_failed':
            case 'invalid_token':
            case 'token_doesnt_exist':
            case 'token_used':
            case 'token_expired':
                return authenticationRoutes.signIn;
            case 'account_verified':
                return authenticationRoutes.signUpWelcome;
            default:
                return null;
        }
    };

    const getHubCallBackMessage = keyMessage => {
        switch (keyMessage) {
            case 'account_not_found':
                return translate('Account not found');
            case 'account_exists':
                return translate('Account already exists');
            case 'payment_error':
                return translate('Please try again later or contact your bank');
            case 'card_is_attached':
                return translate('Your card is successfully attached');
            case 'email_verification_failed':
                return translate('Email verification failed');
            case 'account_verified':
                return translate('Account is successfully verified');
            case 'invalid_token':
                return translate('Your link is not valid');
            case 'token_doesnt_exist':
                return translate('Your link does not exist');
            case 'token_used':
                return translate('Your link has been already used');
            case 'token_expired':
                return translate('Your link is expired');
            case 'backend_translations':
                return [
                    translate('OS'),
                    translate('TV'),
                    translate('day'),
                    translate('Gym'),
                    translate('time'),
                    translate('User'),
                    translate('Euro'),
                    translate('hour'),
                    translate('year'),
                    translate('days'),
                    translate('Name'),
                    translate('Peak'),
                    translate('High'),
                    translate('Taxi'),
                    translate('Male'),
                    translate('Brand'),
                    translate('Level'),
                    translate('today'),
                    translate('weeks'),
                    translate('hours'),
                    translate('month'),
                    translate('years'),
                    translate('Model'),
                    translate('Kiosk'),
                    translate('Other'),
                    translate('Medium'),
                    translate('Timers'),
                    translate('Uptime'),
                    translate('Subnet'),
                    translate('config'),
                    translate('months'),
                    translate('Tablet'),
                    translate('Street'),
                    translate('Square'),
                    translate('Indoor'),
                    translate('Totals'),
                    translate('Kiosks'),
                    translate('minute'),
                    translate('second'),
                    translate('Female'),
                    translate('Version'),
                    translate('network'),
                    translate('Carrier'),
                    translate('Trigger'),
                    translate('Command'),
                    translate('Changed'),
                    translate('Current'),
                    translate('Time on'),
                    translate('Airport'),
                    translate('Outdoor'),
                    translate('Unrated'),
                    translate('minutes'),
                    translate('seconds'),
                    translate('Strength'),
                    translate('Pin code'),
                    translate('Alive at'),
                    translate('Smil url'),
                    translate('Capacity'),
                    translate('External'),
                    translate('Time off'),
                    translate('Portrait'),
                    translate('1+ stars'),
                    translate('2+ stars'),
                    translate('3+ stars'),
                    translate('4+ stars'),
                    translate('Platform'),
                    translate('Under 18'),
                    translate('Time zone'),
                    translate('Screen id'),
                    translate('resources'),
                    translate('Available'),
                    translate('Simulator'),
                    translate('Unplugged'),
                    translate('US Dollar'),
                    translate('Landscape'),
                    translate('Created at'),
                    translate('Updated at'),
                    translate('Crossroads'),
                    translate('Web version'),
                    translate('Lit version'),
                    translate('APK version'),
                    translate('ADB enabled'),
                    translate('Design name'),
                    translate('OS Build ID'),
                    translate('Device type'),
                    translate('Reported at'),
                    translate('millisecond'),
                    translate('65 and over'),
                    translate('Manufacturer'),
                    translate('Product name'),
                    translate('Total memory'),
                    translate('Host version'),
                    translate('Is connected'),
                    translate('5 stars only'),
                    translate('milliseconds'),
                    translate('Armenian Dram'),
                    translate('Invalid email'),
                    translate('Russian Ruble'),
                    translate('LED Billboard'),
                    translate('Last Connected'),
                    translate('Bionic version'),
                    translate('Chrome version'),
                    translate('Low power mode'),
                    translate('LED Billboards'),
                    translate('Indoor Screens'),
                    translate('Cpu temperature'),
                    translate('Browser version'),
                    translate('Lit app version'),
                    translate('Is wifi enabled'),
                    translate('Trading Centres'),
                    translate('Shopping Center'),
                    translate('Campaign stopped'),
                    translate('Email is invalid'),
                    translate('Last Disconnected'),
                    translate('Connection Uptime'),
                    translate('Device year class'),
                    translate('Platform features'),
                    translate('Platform API level'),
                    translate('Lit native version'),
                    translate('Connection Downtime'),
                    translate('Media debug enabled'),
                    translate('Cellular generation'),
                    translate('OS build fingerprint'),
                    translate('OS internal build ID'),
                    translate('Side loading enabled'),
                    translate('Socket debug enabled'),
                    translate('Bionic native version'),
                    translate('Is internet reachable'),
                    translate('Smil refresh interval'),
                    translate('Stats submit interval'),
                    translate('Playlist debug enabled'),
                    translate('Is connection expensive'),
                    translate('name is a required field'),
                    translate('Email is a required field'),
                    translate('Platform settings version'),
                    translate('Supported CPU architectures'),
                    translate('Email must be a valid email'),
                    translate('password is a required field'),
                    translate('firstname is a required field'),
                    translate('Campaign is created or updated'),
                    translate('mobilephone is a required field'),
                    translate('Advelit | Advertising Screens Network'),
                    translate('Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'),
                    translate('Advelit is your online digital advertising platform, connecting brands with screen owners in key locations like malls, gyms, beauty salons, cafes and restaurants. Choose from LED screens, taxi ads, and outdoor billboards to target your audience effectively. Streamline your campaigns with our innovative marketplace.'),
                    translate('Advelit: Online Outdoor Advertising Platform - Transform your brand visibility with Advelit marketplace for digital out-of-home advertising. Connect with screen owners in high-traffic locations like malls, gyms, beauty salons, cafes, and restaurants. Choose from a wide range of digital advertising options including LED screens, taxi ads, and outdoor billboards to effectively target your audience. Streamline your campaigns and increase brand exposure.'),
                ];
            default:
                return translate(keyMessage);
        }
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const keyType = searchParams.get('hcrType');
    const keyMessage = searchParams.get('hcrMessage');

    useEffect(() => {
        const metaParams = ['fbclid', 'igshid'].map(key => ({
            key,
            utm_source: key === 'fbclid' ? 'meta' : 'instagram',
            value: searchParams.get(key),
        })).filter(({ value }) => !!value);

        const keysToSave = [
            'utm_source',
            'utm_campaign',
            'device',
        ];

        const keySaver = (key, value) => {
            if (value) {
                setCookie(key, value);
                setCookie(`${key}-internal`, value, 365, false);
            }
        };

        keysToSave.map(key => ({
            key,
            value: searchParams.get(key),
        })).forEach(item => keySaver(item.key, item.value));

        if (metaParams.length && !searchParams.get('utm_source')) {
            metaParams.forEach(item => keySaver('utm_source', item.utm_source));
        }

        if (keyType && keyMessage) {
            const redirectURL = getRedirectURL(keyMessage);

            keyType && import('../../utils/addToast').then(({ default: addToast }) => addToast({
                type: keyType,
                message: translate(getHubCallBackMessage(keyMessage)),
            }));

            if (redirectURL) {
                navigate(redirectURL, {
                    replace: true,
                });
            } else {
                searchParams.delete('hcrType');
                searchParams.delete('hcrMessage');

                setSearchParams(searchParams, {
                    replace: true,
                });
            }
        }
    }, [keyType, keyMessage]); // eslint-disable-line

    return null;
};

export default AppUrlListener;
