import { Fragment, useCallback, useMemo } from 'react';
import { conditionalSpread, rem } from 'clyne-core';
import { ArrowDown01Icon } from '@hugeicons/react';
import TextTransition from 'react-text-transition';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Flex from '../flex';
import DropDown from '../dropDown';
import NumberFlow from '../numberFlow';

import { selectedPriceModeState } from '../../state';

import translate from '../../utils/translate';

import './index.scss';

const ScreenPrice = props => {
    const {
        gap,
        font,
        data,
        column,
        prefix,
        externalValue,
        appearance = 'default',
    } = props;

    const getPrice = period => data?.prices?.find(price => price?.period === period)?.price || 0;

    const [selectedState, setSelected] = useRecoilState(selectedPriceModeState);

    const externalMode = !isNaN(externalValue);

    const selected = externalMode ? externalValue : selectedState;

    const prices = [
        {
            value: getPrice(1),
            label: translate('Daily'),
        },
        {
            value: getPrice(2),
            label: translate('Weekly'),
        },
        {
            value: getPrice(3),
            label: translate('Monthly'),
        },
        {
            value: data?.pricePerSlot,
            label: translate('Per Play'),
        },
    ].map((item, index) => ({
        ...item,
        id: index,
    }));

    const value = useMemo(() => prices?.[selected]?.value, [prices, selected]);

    const numberFlowRenderer = useCallback(() => (
        <NumberFlow
            font={font}
            value={data?.comingSoon ? 0 : value}
            {...conditionalSpread({
                prefix: `${translate('TBA')} `,
            }, data?.comingSoon)}
        />
    ), [value, data?.comingSoon, font]);

    return !!data && (
        <div
            style={conditionalSpread({
                gap: rem(gap),
            }, gap)}
            className={classNames(
                'screen-price-holder',
                {
                    column,
                }
            )}
        >
            {prefix ? (
                <Flex gap={6}>
                    <p>{prefix}</p>
                    {numberFlowRenderer()}
                </Flex>
            ) : numberFlowRenderer()}
            {!column && (
                <svg width='4' height='10' viewBox='0 0 4 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M0.28921 8.91234L2.5075 0.831809C2.57432 0.594591 2.66014 0.454018 2.7528 0.372656C2.84018 0.295929 2.95796 0.25 3.13871 0.25C3.34237 0.25 3.49129 0.31544 3.58824 0.410795C3.68481 0.505777 3.75 0.650202 3.75 0.847176C3.75 0.95091 3.73748 1.03296 3.71209 1.12166L1.48753 9.21137L1.48718 9.2126C1.42365 9.4339 1.34229 9.56053 1.25309 9.6341C1.16728 9.70486 1.04569 9.75 0.850727 9.75C0.632522 9.75 0.487242 9.67993 0.396892 9.58873C0.305238 9.49621 0.25 9.36169 0.25 9.19712C0.25 9.09164 0.266631 8.99777 0.288942 8.91335L0.28921 8.91234Z' />
                </svg>
            )}
            {appearance === 'toggle' ? (
                <div
                    className={classNames(
                        'screen-price-switcher-d-label',
                        {
                            'read-only': externalMode,
                        }
                    )}
                    onClick={() => setSelected(prices[selected + 1] ? selected + 1 : 0)}
                >
                    <TextTransition inline>
                        {prices[selected]?.label}
                    </TextTransition>
                </div>
            ) : appearance === 'dropDown' ? (
                <div className='screen-price-switcher-dropdown'>
                    <DropDown
                        selfSizing
                        data={prices}
                        passState={false}
                        labelSelector='label'
                        onChange={setSelected}
                        defaultSelected={selected}
                        label={translate('Pricing Model')}
                        labelRenderer={({ value }) => (
                            <div className='screen-price-switcher-d-label'>
                                <span>{value}</span>
                                <ArrowDown01Icon type='standard' />
                            </div>
                        )}
                    />
                </div>
            ) : (
                <div className='screen-price-switchers'>
                    {prices.map((price, index) => (
                        <Fragment key={index}>
                            {!!index && (
                                <small />
                            )}
                            <button
                                onClick={() => setSelected(index)}
                                style={{
                                    '--max-width': price.label.slice(1).length,
                                }}
                                className={classNames(
                                    {
                                        active: selected === index,
                                    }
                                )}
                            >
                                <span>{price.label.slice(0, 1)}</span>
                                <span>{price.label.slice(1)}</span>
                            </button>
                        </Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};

ScreenPrice.propTypes = {
    gap: PropTypes.number,
    font: PropTypes.shape({
        size: PropTypes.number,
        weight: PropTypes.number,
    }),
    data: PropTypes.object,
    prefix: PropTypes.any,
    column: PropTypes.bool,
    externalValue: PropTypes.number,
    appearance: PropTypes.oneOf([
        'default',
        'dropDown',
        'toggle',
    ]),
};

export default ScreenPrice;
