import { ArrowRight02Icon, Cancel01Icon, NewReleasesIcon } from '@hugeicons/react';
import { useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import To from '../to';
import Flex from '../flex';
import Translate from '../translate';

import useInlineTranslation from '../../hooks/useInlineTranslation';

import { iconSize, setCookie } from '../../helpers';

import { inlineUpSaleOpenState } from '../../state';

import './index.scss';

const InlineUpSale = memo(function InlineUpSale(props) {
    const {
        cta = true,
        appearance,
    } = props;

    const setInlineUpSaleOpen = useSetRecoilState(inlineUpSaleOpenState);

    const { getInlineTranslation } = useInlineTranslation();

    const newIcon = (
        <div className='position-relative inline-up-sale-new-icon'>
            <NewReleasesIcon
                style={iconSize(appearance === 'row' ? 40 : 30)}
            />
            <Flex
                align='center'
                justify='center'
                className='absolute-splash'
            >
                {getInlineTranslation({
                    en: 'New',
                    hy: 'Նոր',
                })}
            </Flex>
        </div>
    );

    const title = (
        <div className='inline-up-sale-title'>
            <Translate>Introducing Dynamic Campaigns</Translate>
        </div>
    );

    const content = (
        <p>
            <Translate>Target right weekdays & times, pay only for shown ads and save up to 87% on costs, while keeping your impact!</Translate>
            {` `}
            {!!cta && (
                <To url='/s'>
                    <Translate>Try It Now</Translate>
                    <ArrowRight02Icon
                        type='standard'
                        style={iconSize(18)}
                    />
                </To>
            )}
        </p>
    );

    const closeButton = (
        <button
            onClick={() => {
                setInlineUpSaleOpen(false);
                setCookie('inlineUpSaleDismissed', true, 7, false);
            }}
        >
            <Cancel01Icon
                style={iconSize(16)}
            />
        </button>
    );

    return (
        <div
            className={classNames(
                'inline-up-sale-holder',
                `a-${appearance}`,
            )}
        >
            {appearance === 'row' ? (
                <>
                    <Flex
                        gap={8}
                        align='center'
                    >
                        {newIcon}
                        {title}
                        <small />
                        {content}
                    </Flex>
                    {closeButton}
                </>
            ) : appearance === 'column' ? (
                <>
                    <Flex
                        column
                        gap={4}
                        stretch={false}
                    >
                        <Flex
                            gap={8}
                            align='center'
                        >
                            {newIcon}
                            {title}
                        </Flex>
                        {content}
                    </Flex>
                    {closeButton}
                </>
            ) : (
                <Flex
                    column
                    gap={8}
                >
                    <Flex
                        gap={8}
                        align='center'
                    >
                        {newIcon}
                        {title}
                    </Flex>
                    {content}
                    {closeButton}
                </Flex>
            )}
        </div>
    );
});

InlineUpSale.propTypes = {
    cta: PropTypes.bool,
    appearance: PropTypes.oneOf([
        'row',
        'column',
        'compact',
    ]),
};

export default InlineUpSale;
