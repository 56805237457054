const linkedScreens = [
    [33, 34],
    [72, 73],
    [74, 75],
    [10, 20, 23],
    [132, 133, 134, 135, 136, 137, 138, 139],
    [140, 141, 142, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 157, 158, 159, 160, 161, 162, 164, 171, 173],
];

export default linkedScreens;
