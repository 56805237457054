import { useRecoilValue } from 'recoil';
import React from 'react';

import Loader from '../loader';
import NoData from '../noData';
import Translate from '../translate';
import ScreenPaper from '../screenPaper';

import { favouritesState } from '../../state';

import useConnector from '../../hooks/useConnector';

import './index.scss';

const Favourites = () => {
    const favourites = useRecoilValue(favouritesState);

    const { data, initialLoading } = useConnector(`/screens/${favourites?.join(',')}`, null, {}, true, favourites?.length);

    return (
        <div className='favorites-holder'>
            {favourites?.length ? (
                initialLoading ? (
                    <Loader placement='popover' />
                ) : (
                    <div className='favorites-grid'>
                        {data?.map(screen => (
                            <ScreenPaper
                                data={screen}
                                priceAsToggle
                                key={screen.id}
                                appearance='column'
                            />
                        ))}
                    </div>
                )
            ) : (
                <NoData
                    type='favorite'
                    size='small'
                    subTitle={
                        <Translate>You don't have any favorites. ...yet!</Translate>
                    }
                />
            )}
        </div>
    );
};

export default Favourites;
