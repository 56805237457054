import { AlertDiamondIcon, Cancel01Icon, CancelSquareIcon, MoonIcon, PlusSignIcon, SquareLock01Icon } from '@hugeicons/react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { conditionalSpread } from 'clyne-core';
import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Flex from '../flex';
import Tooltip from '../tooltip';
import Translate from '../translate';
import CountInput from '../countInput';
import LockedScreenInfo from '../lockedScreenInfo';

import translate from '../../utils/translate';
import humanizeDurationWithTranslation from '../../utils/humanizeDurationWithTranslation';

import { linkedScreenModalState, selectedVirtualSlotsState } from '../../state';

import { getLSItem, iconSize, idFormatter, isScreenSelectable, roundDownToNearestMinute } from '../../helpers';

import './index.scss';

const ScreenCampaignActions = memo(function ScreenCampaignActions(props) {
    const {
        data,
        locked,
        onSelect,
        selected,
        available,
        canRemove = true,
        checkForAvailable,
        canReduceVirtualSlot,
    } = props;

    const setLinkedScreenModal = useSetRecoilState(linkedScreenModalState);
    const [selectedVirtualSlots, setSelectedVirtualSlots] = useRecoilState(selectedVirtualSlotsState);

    const onAdd = () => {
        if (data?.linked?.length && !getLSItem('linkedScreenNoticeDismissed')) {
            setLinkedScreenModal({
                id: data?.id,
                linked: data?.linked,
                source: 'checkbox',
                title: [idFormatter(data?.id), data?.name].filter(Boolean).join(' - '),
            });
        } else {
            !!onSelect && onSelect(data);
        }
    };

    return (selected && data?.maxVirtualSlots > 1) ? (
        <Flex
            gap={8}
            shrink={0}
            minHeight={32}
            stretch={false}
        >
            <CountInput
                canReduce={canReduceVirtualSlot}
                value={selectedVirtualSlots[data?.id] || 1}
                max={data?.maxVirtualSlots < 1 ? 1 : (data?.maxVirtualSlots || 1)}
                canAdd={checkForAvailable ? !!available : true}
                title={translate('Repetition in _LOOP_DURATION_', {
                    _LOOP_DURATION_: humanizeDurationWithTranslation(roundDownToNearestMinute(data?.loopDuration * 1000)),
                })}
                onChange={value => setSelectedVirtualSlots(val => data?.linked?.length ? data?.linked?.reduce((prev, current) => ({
                    ...val,
                    ...prev,
                    [current]: value,
                }), {}) : ({
                    ...val,
                    [data?.id]: value,
                }))}
            />
            <Tooltip
                position='top'
                content={locked ? (
                    <LockedScreenInfo
                        workspaceId={data?.workspaceId}
                    />
                ) : canRemove ? (
                    <Translate>Remove</Translate>
                ) : (
                    <Translate>You can't remove the only screen in the campaign</Translate>
                )}
            >
                <button
                    {...conditionalSpread({
                        onClick: () => onSelect(data),
                    }, !locked && !!canRemove)}
                    className={classNames(
                        'sp-meta-detail-action',
                        {
                            disabled: locked || !canRemove,
                        }
                    )}
                >
                    {locked ? (
                        <SquareLock01Icon
                            variant='solid'
                            style={iconSize(16)}
                        />
                    ) : (
                        <CancelSquareIcon
                            variant='solid'
                            style={iconSize(16)}
                        />
                    )}
                </button>
            </Tooltip>
        </Flex>
    ) : (
        <button
            onClick={() => {
                if (isScreenSelectable(data) && !locked) {
                    selected ? onSelect(data) : onAdd();
                }
            }}
            className={classNames(
                'sp-cta-button',
                data?.pendingForReview ? 't-warning' : data?.comingSoon ? 't-hero' : locked ? 't-warning' : selected ? 't-danger' : '',
                {
                    hidden: !canRemove,
                    'pointer-events-none': !isScreenSelectable(data),
                }
            )}
        >
            {data?.pendingForReview ? (
                <AlertDiamondIcon
                    variant='solid'
                    style={iconSize(16)}
                />
            ) : data?.comingSoon ? (
                <MoonIcon
                    variant='solid'
                    style={iconSize(16)}
                />
            ) : (locked ? (
                <SquareLock01Icon
                    variant='solid'
                    style={iconSize(16)}
                />
            ) : (selected ? (
                <Cancel01Icon
                    variant='solid'
                    style={iconSize(16)}
                />
            ) : (
                <PlusSignIcon
                    variant='solid'
                    style={iconSize(16)}
                />
            )))}
            {data?.pendingForReview ? (
                <Translate>In Review</Translate>
            ) : data?.comingSoon ? (
                <Translate>Soon</Translate>
            ) : (locked ? (
                <Translate>Locked</Translate>
            ) : (selected ? (
                <Translate>Remove</Translate>
            ) : (
                <Translate>Add</Translate>
            )))}
            {locked && (
                <Tooltip
                    position='top'
                    content={(
                        <LockedScreenInfo workspaceId={data?.workspaceId} />
                    )}
                >
                    <div className='absolute-splash' />
                </Tooltip>
            )}
        </button>
    );
});

ScreenCampaignActions.propTypes = {
    data: PropTypes.object,
    locked: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    available: PropTypes.bool,
    canRemove: PropTypes.bool,
    checkForAvailable: PropTypes.bool,
    canReduceVirtualSlot: PropTypes.bool,
};

export default ScreenCampaignActions;
