import React, { memo, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilState } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { splashLoadersSate } from '../../state';

import { guid } from '../../helpers';

import './index.scss';

const uid = guid();

const Loader = memo(function Loader(props) {
    const {
        main,
        splash,
        middle,
        loading,
        absolute,
        placement,
        type = 'spinner',
        portalElement = document.body,
    } = props;

    const [splashLoaders, setSplashLoaders] = useRecoilState(splashLoadersSate);

    useEffect(() => {
        splash && setSplashLoaders(val => ([...val, uid]));

        return () => {
            splash && setSplashLoaders(val => val.filter(id => id !== uid));
        };
    }, []); // eslint-disable-line

    const spinnerRenderer = useCallback(middle => (
        <div
            className={classNames(
                `loader-spinner`,
                {
                    middle,
                    absolute,
                }
            )}
        />
    ), [absolute]);

    return !splash && (type === 'spinner' ? (main ? createPortal((
        <ul className='main-loader-holder'>
            <li
                className={classNames(
                    {
                        active: !!splashLoaders?.length,
                    }
                )}
            >
                {spinnerRenderer(true)}
            </li>
        </ul>
    ), document.body) : placement ? (
        <div
            className={classNames(
                `loader-placement-holder`,
                `pt-${placement}`,
            )}
        >
            {spinnerRenderer(true)}
        </div>
    ) : (absolute ? (
        <div className='absolute-loader'>
            {spinnerRenderer(middle)}
        </div>
    ) : spinnerRenderer(middle))) : createPortal((
        <div
            className={classNames(
                `loader-bar-holder`,
                {
                    'active': loading,
                }
            )}
        />
    ), portalElement));
});

Loader.propTypes = {
    main: PropTypes.bool,
    type: PropTypes.oneOf([
        'spinner',
        'bar',
    ]),
    splash: PropTypes.bool,
    middle: PropTypes.bool,
    loading: PropTypes.bool,
    absolute: PropTypes.bool,
    placement: PropTypes.oneOf([
        'popover',
        'static',
        'auth',
    ]),
    portalElement: PropTypes.any,
};

export default Loader;
