import { useRecoilValue } from 'recoil';

import languages from '../configs/languages';

import { languageState } from '../state';

const defaultLanguage = languages.find(lang => lang.default);

const useInlineTranslation = () => {
    const language = useRecoilValue(languageState);

    const getInlineTranslation = obj => {
        return obj?.[language?.code] || obj?.[defaultLanguage.code];
    };

    return ({
        getInlineTranslation,
    });
};

export default useInlineTranslation;
