import React, { Fragment, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

import Loader from './components/loader';

import Layout from './containers/layout';

import useDevice from './hooks/useDevice';
import useWindowResize from './hooks/useWindowResize';

import AntHydrate from './hydrators/antHydrate';
import HookHydrate from './hydrators/hookHydrate';
import StateHydrate from './hydrators/stateHydrate';
import WhiteLabelHydrate from './hydrators/whiteLabelHydrate';

import { isWhiteLabel, subDomain } from './helpers';

import lazyWithRetry from './utils/lazyWithRetry';

import './assets/scss/skeleton.scss';

const Splash = lazyWithRetry(() => import('./components/splash').then());
const ErrorFallback = lazyWithRetry(() => import('./components/errorFallback').then());

const kit = subDomain === 'kit';

const App = () => {
    const {
        screenHeight,
    } = useWindowResize();

    const {
        isMobile,
    } = useDevice();

    useEffect(() => {
        document.documentElement.style.setProperty('--vh', isMobile ? screenHeight + 'px' : '100vh');
    }, [screenHeight, isMobile]);

    useEffect(() => {
        document.documentElement.style.setProperty('--vih', window.innerHeight + 'px');
    }, [isMobile]);

    useEffect(() => {
        document.documentElement.style.setProperty('--screen-height', window.screen.height + 'px');
    }, [isMobile]);

    const ConditionalWhiteLabelHydrate = isWhiteLabel ? WhiteLabelHydrate : Fragment;

    const loader = (
        <Loader splash />
    );

    return (
        <BrowserRouter>
            <ErrorBoundary
                fallback={(
                    <Suspense fallback={loader}>
                        <ErrorFallback />
                    </Suspense>
                )}
            >
                <ConditionalWhiteLabelHydrate>
                    <StateHydrate>
                        <HookHydrate>
                            <AntHydrate>
                                {kit ? (
                                    <Suspense fallback={loader}>
                                        <Splash />
                                    </Suspense>
                                ) : (
                                    <Layout />
                                )}
                            </AntHydrate>
                        </HookHydrate>
                    </StateHydrate>
                </ConditionalWhiteLabelHydrate>
            </ErrorBoundary>
            <Loader main />
        </BrowserRouter>
    );
};

export default App;
